import CircularProgress from '@material-ui/core/CircularProgress'

import { ContainerLoaderRootWrap } from './styles'

const ContainerLoader = () => {
    return (
        <ContainerLoaderRootWrap>
            <CircularProgress />
        </ContainerLoaderRootWrap>
    )
}

export default ContainerLoader
