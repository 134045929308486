import React, { FC, useState, useEffect, useMemo } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { WarningSVG, CrossSVG, SettingsSVG } from 'assets/common'
import { IconPhoneSVG } from 'assets/icons'
// import NewFeatureBanner from 'components/shared/components/NewFeatureBanner'
import { useBilling, useResizeDevice } from 'context'
import { getCookie } from 'helpers'
import { useAppSelector } from 'hooks'
import { TARIFF } from 'types/billing.types'
import { TCompany } from 'types/data.types'
import { ROUTES } from 'types/routes'

import * as S from './styles'

interface TrialExpiredProps {
    company: TCompany
    isManager: boolean
}

const InfromationBlock: FC<TrialExpiredProps> = ({ company, isManager }) => {
    const { t } = useTranslation('translation')
    const { isMobile } = useResizeDevice()
    const location = useLocation()

    const validRoutes = ['/calendar', '/spaces']

    const isValidRoute = validRoutes.some((route) => location.pathname.includes(route))

    const timeSessionStorage = sessionStorage.getItem('trialExpiredDismissed')

    const [showBlock, setShowBlock] = useState(false)
    const [showBlockBlockNewFeature, setShowBlockNewFeature] = useState<boolean>()

    const statusFeature = getCookie('statusFeature')

    const { turnOnExperimentalFeatures } = useAppSelector(({ user }) => user)

    const isDismissed = useMemo(() => {
        return timeSessionStorage ? moment().isBefore(moment(timeSessionStorage)) : false
    }, [timeSessionStorage])

    const daysRemaining = useMemo(() => {
        if (company.trialExpiresAt) {
            return Math.round((new Date(company.trialExpiresAt).getTime() - new Date().getTime()) / 60000 / 60 / 24)
        }
    }, [company?.trialExpiresAt])

    useEffect(() => {
        if (
            !isDismissed &&
            daysRemaining &&
            daysRemaining > 0 &&
            daysRemaining < 31 &&
            [TARIFF.TRIAL].includes(company?.tariff as TARIFF)
        ) {
            setShowBlock(true)
        }
        if ([TARIFF.TRIAL_EXPIRED].includes(company?.tariff as TARIFF)) {
            setShowBlock(true)
        }
    }, [company?.tariff, daysRemaining])

    useEffect(() => {
        if (statusFeature === 'false') {
            setShowBlockNewFeature(true)
        } else {
            setShowBlockNewFeature(turnOnExperimentalFeatures)
        }

        sessionStorage.setItem('newEditBooking', `${turnOnExperimentalFeatures}`)
    }, [turnOnExperimentalFeatures])

    const shouldDisplayCloseButton = useMemo(() => {
        return company?.tariff === TARIFF.TRIAL
    }, [company?.tariff])

    const hiddenTrial = isMobile || !showBlock

    const close = () => {
        const expiryDate = moment().add(1, 'day').toISOString()
        sessionStorage.setItem('trialExpiredDismissed', expiryDate)
        setShowBlock(false)
    }

    return (
        <>
            {/*{hiddenTrial && !showBlockBlockNewFeature && isValidRoute && (*/}
            {/*    <NewFeatureBanner*/}
            {/*        textComponent={*/}
            {/*            <div>*/}
            {/*                {t("Hi! We've added a new experimental features. You can enable and try it in the ")}*/}
            {/*                <Link to={ROUTES.PROFILE}>{t('Profile settings')}</Link>*/}
            {/*                <span> </span>*/}
            {/*                {t("if you'd like!")}*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*    ></NewFeatureBanner>*/}
            {/*)}*/}

            {!hiddenTrial && isManager && (
                <S.TrialExpiredContainer className={'df align-items-center'}>
                    <WarningSVG width={32} height={32} />
                    <S.TrialExpiredTitle className={'ml-4'}>
                        {company?.tariff === TARIFF.TRIAL_EXPIRED ? (
                            t('Your trial has expired')
                        ) : (
                            <>
                                {t('Your trial expires in')} <b>{daysRemaining}</b> {t('days')}
                            </>
                        )}
                    </S.TrialExpiredTitle>

                    <S.TrialExpiredButton href="https://yoffix.com/book-a-demo" target={'_blank'} className={'ml-8'}>
                        <IconPhoneSVG className={'phone-svg'} /> {t('Book demo call')}
                    </S.TrialExpiredButton>

                    {shouldDisplayCloseButton && (
                        <S.TrialExpiredButtonClose onClick={close} className={'ml-8'}>
                            <CrossSVG width={16} height={16} className={'close-svg'} />
                        </S.TrialExpiredButtonClose>
                    )}
                </S.TrialExpiredContainer>
            )}
        </>
    )
}

export default InfromationBlock
