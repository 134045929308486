export enum ROUTES {
    HOME = '/',
    CALENDAR = '/calendar',
    SPACE_SCREEN = '/spaces',
    ROOMS = '/rooms',
    RESOURCES = '/resources',
    SETTINGS = '/settings',
    SETTINGS_SLACK = '/install-slack',
    SETTINGS_MS_CALENDAR = '/ms-calendar',
    SETTINGS_MS_TEAMS = '/ms-teams',
    PROFILE = '/profile',
    SCHEDULE = '/schedule',
    INITIAL_SETUP = '/initial-setup',
}

export enum AUTH_ROUTES {
    LOGIN = '/login',
    LOGIN_SSO = '/login-sso',
    SIGNUP = '/signup',
    PASSWORD_RECOVERY = '/recovery',
    CONFIRM_EMPLOYEE = '/employee/confirm/:email/:tempPassword',
    CONFIRM_COMPANY = '/confirmSignUp',
    CHECK_EMAIL = '/check-email',
    FORGOT_PASSWORD = '/forgot-password/:email/:tempPassword',
    LOGOUT = '/logout',
    MS_LANDING_PAGE = '/ms-landing-page',
    MS_LOGIN_PAGE = '/ms-login-page',
    EXPLORE_DEMO = '/explore-demo',
}

export enum MANAGER_ROUTES {
    DASHBOARD = '/dashboard',
    EMPLOYEES = '/employees',
    SUBSCRIPTIONS = '/subscriptions',
    REPORTS = '/reports',
    OFFICES = '/offices',
    ADMIN_PANEL = '/admin-panel',
}

export enum DASHBOARD_ROUTES {
    ATTENDANCE = 'attendance',
}

export enum EMPLOYEES_ROUTES {
    TEAMS = 'teams',
    POSITIONS = 'positions',
}

export enum SETTINGS_ROUTES {
    BOOKING_ATTENDANCE = 'booking-attendance',
    NOTIFICATION_CENTER = 'notification-center',
    PRICING = 'pricing',
    INVOICES = 'invoices',
    COMPANY = 'company',
    TEAMS = 'teams',
    INTEGRATIONS = 'integrations',
    COVID = 'covid',
    USERS = 'users',
    BILLING = 'billing',
    POSITIONS = 'positions',
    RULES = 'rules',
}

export enum INTEGRATIONS_ROUTES {
    UserSync = 'user-sync',
    Sso = 'sso',
    Tools = 'tools',
}

export enum SCHEDULE_ROUTES {
    HOME = 'home',
    EDIT = 'edit',
    SPACE_MAP = 'space-map',
    SCHEDULE = 'schedule',
    MEMBERS = 'members',
    SPACES = 'spaces',
    CALENDAR = 'calendar',
}
