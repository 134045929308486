import moment from 'moment/moment'

import { getAWSFormatDate, getHorizonDay } from 'helpers'
import * as T from 'types/data.types'

export const getEmployeesFromBookings = (bookings: Array<T.PickerReservableBooking>): Array<T.MainEmployeeData> => {
    if (bookings.length === 0) {
        return [] as T.MainEmployeeData[]
    }

    return bookings.reduce((employeesArray: Array<T.MainEmployeeData>, book) => {
        const empCurrent = book.Employee
        if (!book.isBlocked && empCurrent && !employeesArray.find((emp) => emp && emp.id === empCurrent?.id)) {
            employeesArray.push(empCurrent)
        }
        return employeesArray
    }, [])
}

export const getHorizonMaxDate = (isAdmin: boolean, rules?: T.TRules): string | undefined => {
    if (!rules || isAdmin) {
        return undefined
    }

    let horizonDayAmount = getHorizonDay(rules.horizonOfPlanningTimeAmount, rules.horizonOfPlanningTimeUnit)

    // if horizonDayAmount more than 2 month - horizonDayAmount = 2 months
    horizonDayAmount = Math.min(horizonDayAmount, getHorizonDay(2, T.ERulesHorizon.MONTH))

    return getAWSFormatDate(moment().add(horizonDayAmount, 'days'))
}

interface Booking {
    startTime: string
    endTime: string
    Employee: {
        id: string
        [key: string]: any
    }

    [key: string]: any
}

export const findRelevantBooking = (bookings: Booking[], employeeID: string): Booking | null => {
    // TODO use office timezone
    const currentUsersTime = moment.tz('Europe/Berlin')
    const employeeBookings = bookings.filter((interval) => interval.Employee.id === employeeID)
    const relevantBookings = employeeBookings.filter((interval) => {
        return moment(interval.endTime.substring(0, 19)).isAfter(currentUsersTime)
    })

    const currentInterval = relevantBookings.find((interval) => {
        return moment(interval.startTime.substring(0, 19)).isBefore(currentUsersTime)
    })

    if (currentInterval) {
        return currentInterval
    }

    const nextBooking = relevantBookings.sort(
        (a, b) =>
            moment(a.startTime.substring(0, 19)).diff(currentUsersTime) -
            moment(b.startTime.substring(0, 19)).diff(currentUsersTime),
    )[0]

    return nextBooking || employeeBookings[0] || null
}
