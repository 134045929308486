import React, { FC } from 'react'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { Link } from 'react-router-dom'

import { IconIntercom24SVG } from 'assets/icons'
import { UserAvatar } from 'components/atoms'
import { ROUTES } from 'types/routes'

import { sidebarLinks } from '../const'
import * as S from '../styles'

type Props = {
    pathname: string
}
const MobileSidebar: FC<Props> = ({ pathname }) => {
    const { roomManagement, useOnlySpaceScreen, turnOffRooms, turnOffSpaceScreen } = useFlags()

    return (
        <S.MobileSidebarRoot>
            {!roomManagement && !turnOffSpaceScreen && (
                <Link to={ROUTES.SPACE_SCREEN} role="button">
                    <S.SidebarListItem
                        isActive={pathname.replace('-', ' ').includes(ROUTES.SPACE_SCREEN)}
                        className="flex-center"
                    >
                        {sidebarLinks.spaces?.icon}
                    </S.SidebarListItem>
                </Link>
            )}

            {!roomManagement && !useOnlySpaceScreen && (
                <Link to={ROUTES.CALENDAR} role="button">
                    <S.SidebarListItem
                        isActive={pathname.replace('-', ' ').includes(ROUTES.CALENDAR)}
                        className="flex-center"
                    >
                        {sidebarLinks.calendar?.icon}
                    </S.SidebarListItem>
                </Link>
            )}

            {!useOnlySpaceScreen && !turnOffRooms && (
                <Link to={ROUTES.ROOMS} role="button">
                    <S.SidebarListItem
                        isActive={pathname.replace('-', ' ').includes(ROUTES.ROOMS)}
                        className="flex-center"
                    >
                        {sidebarLinks.rooms?.icon}
                    </S.SidebarListItem>
                </Link>
            )}

            <S.SidebarListItem id="intercom-launcher" className="flex-center">
                <IconIntercom24SVG fill="#ffffff" fillOpacity={0.3} />
            </S.SidebarListItem>
            <Link to={ROUTES.PROFILE} role="button">
                <S.ProfileAvatarWrap isActive={false} className="flex-center">
                    <UserAvatar imgSize={40} />
                </S.ProfileAvatarWrap>
            </Link>
        </S.MobileSidebarRoot>
    )
}

export default MobileSidebar
