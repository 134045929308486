import { EventInput } from '@fullcalendar/core'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import {
    BookingEquipment,
    Space,
    RepeatType as MobileScheduleRepeatType,
    Equipment,
} from 'graphql/autogenerate/schemas'
import { getAWSFormatDate, getConvertedEngLocaleDate } from 'helpers'
import { MainEmployeeData, EReservableType } from 'types/data.types'

import { initialStateProps, FormValues } from '../types'

type SelectWeekNumberType = {
    weekNumber: number
    year: number
}

const initialState: initialStateProps = {
    date: getAWSFormatDate(moment()),
    year: moment().year(),
    weekNumber: moment().isoWeek(),
    scheduleBookings: [],
    scheduleMeetings: [],
    events: [],
    placeBookings: [],
    reservables: [],
    open: false,
    startWorkTime: '08:00',
    endWorkTime: '24:00',
    employeeID: '',
    currentReservable: null,
    suggestedSeat: null,
    loadingTimeLine: false,
    loadingBookingInfo: false,
    suggestedSpace: null,
    currentSpace: null,
    currentSpaceForParking: null,
    bookingRepeatType: MobileScheduleRepeatType.NEVER,
    repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(getAWSFormatDate(moment())), 'dddd')],
    selectedTeamEventMembers: [],
    selectedEmployee: null,
    checkInStatus: false,
    currentBooking: null,
    editFormValue: null,
    parkingMapStatus: false,
    currentParkingReservable: null,
    suggestedSeatID: null,
    repeatDate: [],
    isSpaceMapLoading: false,
    spaceLoader: false,
    selectedBookingTime: ['00:00', '24:00'],
    availableTimeForBooking: ['00:00', '24:00'],
    isSeatAvailable: true,
    editBookingPopup: false,
    isEditMode: false,
    isParking: false,
    isNotAvailableSeatReason: '',
    /*TEAMEVENTTIMELINE*/
    reservableID: null,
    openMapState: true,
    isMeetingPlace: false,
    loadingRoomTimeLine: false,
    roomBookings: [],
    roomEvents: [],
}

const editBookingResourceSlice = createSlice({
    name: 'editBookingResource',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<Partial<initialStateProps>>) => {
            return {
                ...state,
                ...action.payload,
            }
        },

        selectCurrentReservable: (state, action: PayloadAction<Equipment | null>) => {
            return {
                ...state,
                currentReservable: action.payload,
            }
        },

        setDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
            }
        },

        selectDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
                currentBooking:
                    (state.currentBooking?.startTime || '').substring(0, 10) !== action.payload
                        ? null
                        : state.currentBooking,
            }
        },

        selectWeekNumber: (state, action: PayloadAction<SelectWeekNumberType>) => {
            return {
                ...state,
                weekNumber: action.payload.weekNumber,
                year: action.payload.year,
            }
        },

        setExistingBookings: (state, action: PayloadAction<Array<BookingEquipment>>) => {
            return {
                ...state,
                // scheduleBookings: action.payload,
                open: true,
                checkInStatus: true,
            }
        },

        setSpace: (state, action: PayloadAction<Space>) => {
            return {
                ...state,
                startWorkTime: action.payload.workingHoursFrom,
                endWorkTime: action.payload.workingHoursTo,
                currentSpace: action.payload,
                currentReservable:
                    state.currentSpace?.officeID !== action.payload.officeID ? null : state.currentReservable, // reset previous selected reservable because it's related to the previous Office
            }
        },

        switchEditBooking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                open: action.payload,
            }
        },

        setLoadingTimeLine: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingTimeLine: action.payload,
            }
        },

        setSpaceLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                spaceLoader: action.payload,
            }
        },

        setLoadingBookingInfo: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingBookingInfo: action.payload,
            }
        },

        setSpaceMapLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSpaceMapLoading: action.payload,
            }
        },

        setCheckInStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                checkInStatus: action.payload,
            }
        },

        setBookingRepeatType: (state, action: PayloadAction<MobileScheduleRepeatType>) => {
            return {
                ...state,
                bookingRepeatType: action.payload,
                repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(state.date), 'dddd')],
            }
        },

        setParking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isParking: action.payload,
            }
        },

        setRepeatDaysOfWeek: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDaysOfWeek: action.payload,
            }
        },

        setTeamEventMembers: (state, action: PayloadAction<Array<MainEmployeeData>>) => {
            return {
                ...state,
                selectedTeamEventMembers: action.payload,
            }
        },

        setEvents(state, action: PayloadAction<EventInput[]>) {
            state.events = action.payload
        },

        addEvent(state, action: PayloadAction<EventInput>) {
            state.events.push(action.payload)
        },

        updateEvent(state, action: PayloadAction<{ id: string; event: Partial<EventInput> }>): void {
            const index = state.events.findIndex((e) => e.id === action.payload.id)
            if (index !== -1) {
                state.events[index] = { ...state.events[index], ...action.payload.event }
            }
        },

        removeEvent(state, action: PayloadAction<string>) {
            state.events = state.events.filter((e) => e.id !== action.payload)
        },

        setCurrentBooking: (state, action: PayloadAction<BookingEquipment | null>) => {
            const payload = action.payload
            if (!payload) {
                return {
                    ...state,
                    currentBooking: null,
                    currentReservable: null,
                }
            }

            return {
                ...state,
                currentBooking: payload,
            }
        },

        changeCurrentBooking: (state, action: PayloadAction<BookingEquipment>) => {
            const payload = action.payload
            if (!payload) {
                return {
                    ...state,
                    currentBooking: null,
                    currentReservable: null,
                }
            }

            return {
                ...state,
                currentBooking: action.payload,
            }
        },

        setFormEdit: (state, action: PayloadAction<FormValues>) => {
            return {
                ...state,
                editFormValue: action.payload,
            }
        },

        setEdit: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isEditMode: action.payload,
            }
        },

        selectRepeatDate: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDate: action.payload,
            }
        },

        setSelectedEmployee: (state, action: PayloadAction<MainEmployeeData | null>) => {
            return {
                ...state,
                selectedEmployee: action.payload,
            }
        },

        setIsSeatAvailable: (
            state,
            action: PayloadAction<{ isSeatAvailable: boolean; isNotAvailableSeatReason: string }>,
        ) => ({
            ...state,
            isSeatAvailable: action.payload.isSeatAvailable,
            isNotAvailableSeatReason: action.payload.isNotAvailableSeatReason,
        }),

        afterSaveEvent: (state) => {
            return {
                ...state,
                loadingBookingInfo: false,
                // currentSpace: null,
                // selectedEmployee: null,
                currentBooking: null,
                // selectedBookingTime: ['00:00', '24:00'],
                checkInStatus: false,
                loadingTimeLine: true,
            }
        },

        /*TEAMEVENTTIMELINE*/

        setReservableId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                reservableID: action.payload,
                openMapState: false,
            }
        },

        setMapState: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                openMapState: action.payload,
            }
        },

        setRoomEvents(state, action: PayloadAction<EventInput[]>) {
            state.roomEvents = action.payload
        },

        addRoomEvent(state, action: PayloadAction<EventInput>) {
            state.roomEvents.push(action.payload)
        },

        updateRoomEvent(state, action: PayloadAction<{ id: string; event: Partial<EventInput> }>): void {
            const index = state.roomEvents.findIndex((e) => e.id === action.payload.id)
            if (index !== -1) {
                state.roomEvents[index] = { ...state.roomEvents[index], ...action.payload.event }
            }
        },

        removeRoomEvent(state, action: PayloadAction<string>) {
            state.roomEvents = state.roomEvents.filter((e) => e.id !== action.payload)
        },

        reset: (state) => ({
            ...initialState,
            // don't reset working time
            employeeID: state.employeeID,
            startWorkTime: state.startWorkTime,
            endWorkTime: state.endWorkTime,
            date: state.date,
        }),
    },
})

export const editBookingResourceActions = editBookingResourceSlice.actions
export default editBookingResourceSlice.reducer
