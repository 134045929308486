import { useEffect, useState, useCallback } from 'react'

import { useQuery } from '@apollo/client'
import { orderBy } from 'lodash'

import { useAuth } from 'context/auth'
import {
    QUERY_LIST_DEPARTMENTS,
    QUERY_LIST_EMPLOYEES,
    QUERY_LIST_SPACES_ALL,
    QUERY_LIST_POSITIONS,
} from 'graphql/queries'
import {
    SUBSCRIPTION_CREATE_DEPARTMENT,
    SUBSCRIPTION_CREATE_EMPLOYEE,
    SUBSCRIPTION_CREATE_POSITION,
    SUBSCRIPTION_CREATE_RESERVABLE,
    SUBSCRIPTION_CREATE_SPACE,
    SUBSCRIPTION_DELETE_DEPARTMENT,
    SUBSCRIPTION_DELETE_EMPLOYEE,
    SUBSCRIPTION_DELETE_POSITION,
    SUBSCRIPTION_DELETE_RESERVABLE,
    SUBSCRIPTION_DELETE_SPACE,
    SUBSCRIPTION_UPDATE_DEPARTMENT,
    SUBSCRIPTION_UPDATE_EMPLOYEE,
    SUBSCRIPTION_UPDATE_POSITION,
    SUBSCRIPTION_UPDATE_RESERVABLE,
    SUBSCRIPTION_UPDATE_SPACE,
} from 'graphql/subscriptions'
import { useReconnectingSubscription } from 'helpers/useReconnectingSubscription'
import { useAppDispatch, useAppSelector } from 'hooks'
import { messageActions } from 'store/slices/message'
import { EmployeeStatus, TDepartment, TEmployee, TPosition, TReservable, TSpace } from 'types/data.types'

import { Department, ModelDepartmentFilterInput } from '../graphql/autogenerate/schemas'
import { EUserGroup } from '../types/user.types'

export const useData = () => {
    const { userAuth, signOut, userGroup } = useAuth()
    const dispatch = useAppDispatch()

    const [employeeQueryParams, setEmployeeQueryParams] = useState({
        skip: false,
        nextToken: null as null | string,
    })

    const employee = useAppSelector(({ user }) => ({
        departmentIDs: user.departmentIDs,
        id: user.id,
    }))

    const isTeamLead = userGroup === EUserGroup.TEAM_LEAD

    const [skipSpaces, setSkipSpaces] = useState(false)
    const [skipDepartments, setSkipDepartments] = useState(false)
    const [skipPositions, setSkipPositions] = useState(false)

    const setDepartmentsFilter = () => {
        if (isTeamLead) {
            return {
                departmentTeamLeadId: { eq: employee?.id },
            } as ModelDepartmentFilterInput
        }
    }

    const { data: dataEmployees } = useQuery<{
        listEmployeesByCompanyAndEmail: { items: Array<TEmployee>; nextToken: string | null }
    }>(QUERY_LIST_EMPLOYEES, {
        variables: {
            companyID: userAuth?.companyId || '',
            nextToken: employeeQueryParams.nextToken,
        },
        skip: employeeQueryParams.skip || !userAuth?.companyId,
    })

    const { data: dataDepartments, refetch: reloadDepartments } = useQuery<{
        listDepartmentsByCompanyAndName: { items: Array<Department> }
    }>(QUERY_LIST_DEPARTMENTS, {
        variables: {
            companyID: userAuth?.companyId || '',
            filter: setDepartmentsFilter(),
        },
        errorPolicy: 'ignore',
        skip: skipDepartments || !userAuth?.companyId,
    })

    const { data: dataPositions } = useQuery<{ listPositionsByCompanyAndName: { items: Array<TPosition> } }>(
        QUERY_LIST_POSITIONS,
        {
            variables: {
                companyID: userAuth?.companyId || '',
            },
            skip: skipPositions || !userAuth?.companyId,
        },
    )

    useQuery<{ listSpacesByCompanyAndName: { items: Array<TSpace> } }>(QUERY_LIST_SPACES_ALL, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: skipSpaces,
        onCompleted: (data) => {
            setSkipSpaces(true)
            setSpaces(data.listSpacesByCompanyAndName.items.filter((item) => item.Office !== null))
            if (data.listSpacesByCompanyAndName.items.length === 0) {
                dispatch(
                    messageActions.messageShown({
                        text: 'No spaces found. Please create an space to continue.',
                        severity: 'warning',
                    }),
                )
            }
        },
    })

    //
    // ---- Subscriptions DEPARTMENTS----
    //
    const { data: dataCreateDepartment } = useReconnectingSubscription<
        { onCreateDepartment: TDepartment },
        { companyID: string }
    >(SUBSCRIPTION_CREATE_DEPARTMENT, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataUpdateDepartment } = useReconnectingSubscription<
        { onUpdateDepartment: TDepartment },
        { companyID: string }
    >(SUBSCRIPTION_UPDATE_DEPARTMENT, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataDeleteDepartment } = useReconnectingSubscription<
        { onDeleteDepartment: TDepartment },
        { companyID: string }
    >(SUBSCRIPTION_DELETE_DEPARTMENT, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    //
    // ---- Subscriptions POSITIONS----
    //
    const { data: dataCreatePosition } = useReconnectingSubscription<
        { onCreatePosition: TPosition },
        { companyID: string }
    >(SUBSCRIPTION_CREATE_POSITION, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataUpdatePosition } = useReconnectingSubscription<
        { onUpdatePosition: TPosition },
        { companyID: string }
    >(SUBSCRIPTION_UPDATE_POSITION, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataDeletePosition } = useReconnectingSubscription<
        { onDeletePosition: TPosition },
        { companyID: string }
    >(SUBSCRIPTION_DELETE_POSITION, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    //
    // ---- Subscriptions EMPLOYEE----
    //
    const { data: dataCreateEmployee } = useReconnectingSubscription<
        { onCreateEmployee: TEmployee },
        { companyID: string }
    >(SUBSCRIPTION_CREATE_EMPLOYEE, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataUpdateEmployee } = useReconnectingSubscription<
        { onUpdateEmployee: TEmployee },
        { companyID: string }
    >(SUBSCRIPTION_UPDATE_EMPLOYEE, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataDeleteEmployee } = useReconnectingSubscription<
        { onDeleteEmployee: TEmployee },
        { companyID: string }
    >(SUBSCRIPTION_DELETE_EMPLOYEE, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })

    //
    // ---- Subscriptions Spaces----
    //
    const { data: dataCreateSpace } = useReconnectingSubscription<{ onCreateSpace: TSpace }, { companyID: string }>(
        SUBSCRIPTION_CREATE_SPACE,
        {
            variables: {
                companyID: userAuth?.companyId || '',
            },
            skip: !userAuth?.companyId,
        },
    )
    const { data: dataUpdateSpace } = useReconnectingSubscription<{ onUpdateSpace: TSpace }, { companyID: string }>(
        SUBSCRIPTION_UPDATE_SPACE,
        {
            variables: {
                companyID: userAuth?.companyId || '',
            },
            skip: !userAuth?.companyId,
        },
    )
    const { data: dataDeleteSpace } = useReconnectingSubscription<{ onDeleteSpace: TSpace }, { companyID: string }>(
        SUBSCRIPTION_DELETE_SPACE,
        {
            variables: {
                companyID: userAuth?.companyId || '',
            },
            skip: !userAuth?.companyId,
            onData: () => dispatch(messageActions.messageShown({ text: 'Office has deleted!', severity: 'success' })),
        },
    )

    //
    // ---- Subscriptions Reservables----
    //
    const { data: dataCreateReservable } = useReconnectingSubscription<
        { onCreateReservable: TReservable },
        { companyID: string }
    >(SUBSCRIPTION_CREATE_RESERVABLE, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataUpdateReservable } = useReconnectingSubscription<
        { onUpdateReservable: TReservable },
        { companyID: string }
    >(SUBSCRIPTION_UPDATE_RESERVABLE, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })
    const { data: dataDeleteReservable } = useReconnectingSubscription<
        { onDeleteReservable: TReservable },
        { companyID: string }
    >(SUBSCRIPTION_DELETE_RESERVABLE, {
        variables: {
            companyID: userAuth?.companyId || '',
        },
        skip: !userAuth?.companyId,
    })

    const [spaces, setSpaces] = useState<Array<TSpace>>([])
    const [employees, setEmployees] = useState<Array<Omit<TEmployee, 'Bookings' | 'Company'>>>([])
    const [departments, setDepartments] = useState<Array<TDepartment | Department>>([])
    const [positions, setPositions] = useState<Array<TPosition>>([])

    const employeesRegistered = employees.filter((item) => item.status === EmployeeStatus.REGISTRED)

    //
    // ---- UseEfects Departments----
    //
    useEffect(() => {
        if (dataDepartments) {
            setSkipDepartments(true)
            setDepartments(dataDepartments.listDepartmentsByCompanyAndName.items)
        }
    }, [dataDepartments])

    useEffect(() => {
        if (dataCreateDepartment) {
            setDepartments(orderBy([...departments, dataCreateDepartment.onCreateDepartment], 'name'))
        }
    }, [dataCreateDepartment])

    useEffect(() => {
        if (dataUpdateDepartment) {
            setDepartments(
                orderBy(
                    departments.map((dep) =>
                        dep.id === dataUpdateDepartment.onUpdateDepartment.id
                            ? dataUpdateDepartment.onUpdateDepartment
                            : dep,
                    ),
                    'name',
                ),
            )
        }
    }, [dataUpdateDepartment])

    useEffect(() => {
        if (dataDeleteDepartment) {
            console.log('dataDeleteDepartment', dataDeleteDepartment)
            setDepartments(departments.filter((dep) => dep.id !== dataDeleteDepartment.onDeleteDepartment.id))
        }
    }, [dataDeleteDepartment])

    //
    // ---- UseEfects Positions----
    //
    useEffect(() => {
        if (dataPositions) {
            setSkipPositions(true)
            setPositions(dataPositions.listPositionsByCompanyAndName.items)
        }
    }, [dataPositions])

    useEffect(() => {
        if (dataCreatePosition) {
            console.log('dataCreatePositions', dataCreatePosition)
            setPositions(orderBy([...positions, dataCreatePosition.onCreatePosition], 'name'))
        }
    }, [dataCreatePosition])

    useEffect(() => {
        if (dataUpdatePosition) {
            console.log('dataUpdatePosition', dataUpdatePosition)
            setPositions(
                orderBy(
                    positions.map((pos) =>
                        pos.id === dataUpdatePosition.onUpdatePosition.id ? dataUpdatePosition.onUpdatePosition : pos,
                    ),
                    'name',
                ),
            )
        }
    }, [dataUpdatePosition])

    useEffect(() => {
        if (dataDeletePosition) {
            console.log('dataDeletePosition', dataDeletePosition)
            setPositions(positions.filter((pos) => pos.id !== dataDeletePosition.onDeletePosition.id))
        }
    }, [dataDeletePosition])

    //
    // ---- UseEfects Employees----
    //
    useEffect(() => {
        if (dataEmployees) {
            const employeeToken = dataEmployees.listEmployeesByCompanyAndEmail.nextToken

            setEmployeeQueryParams({ skip: employeeToken === null, nextToken: employeeToken })
            setEmployees((prevEmployees) =>
                orderBy([...prevEmployees, ...dataEmployees.listEmployeesByCompanyAndEmail.items], 'firstname'),
            )
        }
    }, [dataEmployees])

    useEffect(() => {
        if (dataCreateEmployee) {
            console.log('dataCreateEmployee', dataCreateEmployee)
            setEmployees(orderBy([...employees, dataCreateEmployee.onCreateEmployee], 'firstname'))
        }
    }, [dataCreateEmployee])

    useEffect(() => {
        if (dataUpdateEmployee) {
            console.log('dataUpdateEmployee', dataUpdateEmployee)
            setEmployees(
                orderBy(
                    employees.map((emp) =>
                        emp.id === dataUpdateEmployee.onUpdateEmployee.id ? dataUpdateEmployee.onUpdateEmployee : emp,
                    ),
                    'firstname',
                ),
            )
            setSpaces(
                spaces.map(
                    (space): TSpace => ({
                        ...space,
                        Reservables: {
                            // ToDO: Check if this is correct
                            items: space.Reservables
                                ? space.Reservables.items.map(
                                      (res): TReservable =>
                                          res.employeeID === dataUpdateEmployee.onUpdateEmployee.id
                                              ? {
                                                    ...res,
                                                    Employee: dataUpdateEmployee.onUpdateEmployee,
                                                }
                                              : res,
                                  )
                                : [],
                        },
                    }),
                ),
            )
        }
    }, [dataUpdateEmployee])

    useEffect(() => {
        if (dataDeleteEmployee) {
            if (userAuth?.employeeId === dataDeleteEmployee.onDeleteEmployee.id) {
                signOut()
                return
            }

            console.log('dataDeleteEmployee', dataDeleteEmployee)
            setEmployees(employees.filter((emp) => emp.id !== dataDeleteEmployee.onDeleteEmployee.id))
        }
    }, [dataDeleteEmployee, userAuth?.employeeId])

    //
    // ---- UseEffects Spaces----
    //

    useEffect(() => {
        if (dataCreateSpace) {
            setSpaces(orderBy([...spaces, dataCreateSpace.onCreateSpace], 'name'))
        }
    }, [dataCreateSpace])

    useEffect(() => {
        if (dataUpdateSpace) {
            // console.log('dataUpdateSpace', dataUpdateSpace)
            setSpaces(
                orderBy(
                    spaces.map((space) =>
                        space.id === dataUpdateSpace.onUpdateSpace.id ? dataUpdateSpace.onUpdateSpace : space,
                    ),
                    'name',
                ),
            )
        }
    }, [dataUpdateSpace])

    useEffect(() => {
        if (dataDeleteSpace) {
            console.log('dataDeleteSpace', dataDeleteSpace)
            setSpaces(spaces.filter((space) => space.id !== dataDeleteSpace.onDeleteSpace.id))
        }
    }, [dataDeleteSpace])

    //
    // ---- UseEfects Reservable ----
    //

    const reservableCreate = (resrvbl: TReservable) => {
        setSpaces(
            spaces.map(
                (space): TSpace =>
                    space.id === resrvbl.spaceID && !space.Reservables?.items.find((item) => item.id === resrvbl.id)
                        ? {
                              ...space,
                              Reservables: {
                                  items: space.Reservables ? [...space.Reservables.items, resrvbl] : [resrvbl],
                              },
                          }
                        : space,
            ),
        )
    }

    useEffect(() => {
        if (dataCreateReservable) {
            console.log('dataCreateReservable', dataCreateReservable)
            reservableCreate(dataCreateReservable.onCreateReservable)
        }
    }, [dataCreateReservable])

    useEffect(() => {
        if (dataUpdateReservable) {
            console.log('dataUpdateReservable', dataUpdateReservable)
            setSpaces(
                spaces.map(
                    (space): TSpace =>
                        space.id === dataUpdateReservable.onUpdateReservable.spaceID
                            ? {
                                  ...space,
                                  Reservables: {
                                      items: space.Reservables
                                          ? space.Reservables?.items.map((res) =>
                                                res.id === dataUpdateReservable.onUpdateReservable.id
                                                    ? dataUpdateReservable.onUpdateReservable
                                                    : res,
                                            )
                                          : [],
                                  },
                              }
                            : space,
                ),
            )
        }
    }, [dataUpdateReservable])

    useEffect(() => {
        if (dataDeleteReservable) {
            setSpaces(
                spaces.map(
                    (space): TSpace =>
                        space.id === dataDeleteReservable.onDeleteReservable.spaceID
                            ? {
                                  ...space,
                                  Reservables: {
                                      items: space.Reservables
                                          ? space.Reservables.items.filter(
                                                (res) => res.id !== dataDeleteReservable.onDeleteReservable.id,
                                            )
                                          : [],
                                  },
                              }
                            : space,
                ),
            )
        }
    }, [dataDeleteReservable])

    useEffect(() => {
        reloadDepartments().finally()
    }, [userAuth, employee?.departmentIDs])

    return {
        spaces,
        employees,
        employeesRegistered,
        departments,
        positions,
        reservableCreate,
        reloadDepartments,
    }
}
