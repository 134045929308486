type Money = {
    currency: string
    amount: string
}

export enum BillingType {
    YEAR = 'year',
    MONTH = 'month',
}

export type SubscriptionsPlan = {
    id: string
    name: string
    billingType: BillingType
    billingPeriod: string
    initialPrice: Array<Money>
    recurringPrice: Array<Money>
    price?: string
    custom?: boolean
}

export type BillingSubscriptionStatus =
    | 'ACTIVE'
    | 'PAUSED'
    | 'EXTERNAL_CANCELLED'
    | 'PAST_DUE'
    | 'TERMINATED'
    | 'TRIAL'
    | 'TRIAL_EXPIRED'
    | 'UNKNOWN'

export type BillingSubscription = {
    companyId: string
    status: BillingSubscriptionStatus
    subscriptionPlanId: string
    subscriptionId: string
    expiresAt: string
}

export type TrialType = BillingSubscription & TrialBilling

export type SubscriptionsPlanForCompany = BillingSubscription & SubscribedBillingType & TrialBilling

export type SubscribedBillingType = {
    quantity: number
    updateUrl: string
    cancelUrl: string
    subscribedAt: string
    subscriptionId: string
    discount: DiscountType | null
}

export type TrialBilling = {
    expiresAt: string
}

export type PaymentsType = {
    id: string
    subscriptionId: string
    amount: number
    currency: string
    payoutDate: string
    invoiceUrl: string
}

type DiscountType = {
    type: DISCOUNT
    value: number
}

export enum DISCOUNT {
    FLAT = 'FLAT',
    PERCENTAGE = 'PERCENTAGE',
}

export enum TARIFF {
    FREE = 'FREE',
    STANDARD = 'STANDARD',
    TRIAL = 'TRIAL',
    TRIAL_EXPIRED = 'TRIAL_EXPIRED',
    SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
}

export type FeatureType = { [key: string]: FeatureStatusType }

export type Feature = {
    title: string
    items: FeatureType[]
}

export type FeatureStatusType = 'open' | 'close' | 'soon' | 'plus' | 'plus_soon'
