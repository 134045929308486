import { gql } from '@apollo/client'

import {
    DEPARTMENT,
    EMPLOYEE,
    OFFICE,
    PERSONIO,
    POSITION,
    RESERVABLE,
    SPACE,
    SUBSCRIPTION_PLAN,
    SUBSCRIBED_BILLING,
    TRIAL_BILLING,
    SUBSCRIPTION_FOR_COMPANY,
    RULES,
    SLACK,
    BAMBOOHR,
    MAIN_EMPLOYEE,
    EQUIPMENT,
} from './dataQuery'

export const QUERY_GET_COMPANY = gql`
    ${RULES}
    query GetCompany($id: ID!, $employeeId: ID!) {
        getCompany(id: $id) {
            id
            title
            country
            city
            address
            zipcode
            phoneNumber
            numberOfOffices
            numberOfEmployees
            companyLogo
            trialExpiresAt
            tariff
            Rules {
                ...RulesFields
            }
            Offices {
                items {
                    id
                    name
                    workingFrom
                    workingTo
                }
            }
            calendarIntegration
        }
        getEmployee(id: $employeeId) {
            id
            firstname
            lastname
            cognitoUserId
            email
            photo
            role
            favouriteOfficeID
            favouriteColleagueIDs
            buddyID
            favouriteReservableID
            locale
            departmentIDs
            departmentsString
            Position {
                id
                name
            }
            Office {
                id
                name
            }
            notificationChannels
            shareAttendance
            demoAccountID
            turnOnExperimentalFeatures
        }
    }
`

export const QUERY_LIST_EMPLOYEES = gql`
    ${EMPLOYEE}
    ${OFFICE}
    ${POSITION}
    query ListEmployees($companyID: ID!, $nextToken: String) {
        listEmployeesByCompanyAndEmail(limit: 50, companyID: $companyID, sortDirection: ASC, nextToken: $nextToken) {
            items {
                ...EmployeeFields
                Office {
                    ...OfficeFields
                }
                Position {
                    ...PositionFields
                }
            }
            nextToken
        }
    }
`

export const QUERY_LIST_EMPLOYEES_BY_COMPANY_AND_FIRSTNAME = gql`
    query GetEmployeesByCompanyAndStatusAndFirstname($companyID: ID!) {
        listEmployeesByCompanyAndFirstname(companyID: $companyID, limit: 1000) {
            items {
                id
                firstname
                lastname
                photo
            }
        }
    }
`

export const QUERY_GET_TRACING_CSV = gql`
    query GetContactTracingCsv($employeeID: ID!, $date: AWSDate!) {
        getContactTracingCsv(employeeID: $employeeID, date: $date)
    }
`

export const QUERY_GET_S3_LINK = gql`
    query GetS3Link($operationType: S3OperationType!, $path: String!, $resize: ImageResizeInput) {
        getS3SignedLink(operationType: $operationType, path: $path, resize: $resize)
    }
`

export const QUERY_LIST_RESERVABLES_BY_TYPE = gql`
    query ListReservablesByCompanyAndType($companyID: ID!, $type: String!) {
        listReservablesByCompanyAndType(companyID: $companyID, type: { eq: $type }) {
            items {
                id
                type
            }
        }
    }
`

export const QUERY_LIST_SPACES_ALL = gql`
    ${SPACE}
    ${OFFICE}
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    query ListSpaces($companyID: ID!) {
        listSpacesByCompanyAndName(companyID: $companyID, sortDirection: ASC) {
            items {
                ...SpaceFields
                Office {
                    ...OfficeFields
                }
                Reservables(limit: 1000) {
                    items {
                        ...ReservableFields
                        Employee {
                            ...EmployeeFields
                            Position {
                                ...PositionFields
                            }
                        }
                        Department {
                            name
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_LIST_DEPARTMENTS = gql`
    ${DEPARTMENT}
    ${EMPLOYEE}
    query ListDepartments($companyID: ID!, $filter: ModelDepartmentFilterInput) {
        listDepartmentsByCompanyAndName(companyID: $companyID, filter: $filter, sortDirection: ASC) {
            items {
                ...DepartmentFields
                DepartmentRules {
                    blockReservationOnNonOfficeDays
                    enableMinDaysInOffice
                    minDaysInOffice
                    officeDays
                    minDaysInOfficeTimeUnit
                }
                EmployeeToDepartment {
                    items {
                        Employee {
                            ...EmployeeFields
                        }
                    }
                    nextToken
                }
                TeamLead {
                    ...EmployeeFields
                }
            }
        }
    }
`

export const QUERY_LIST_POSITIONS = gql`
    ${POSITION}
    query ListPositions($companyID: ID!) {
        listPositionsByCompanyAndName(companyID: $companyID, sortDirection: ASC, limit: 1000) {
            items {
                ...PositionFields
            }
        }
    }
`

export const QUERY_CHECKOUT_PAYLINK_URL = gql`
    query GetPaylinkUrlForCheckout($planID: String!, $returnUrl: String!) {
        getPaylinkUrlForCheckout(planID: $planID, returnUrl: $returnUrl) {
            url
        }
    }
`

export const QUERY_GET_SUBSCRIPTIONS_FOR_COMPANY = gql`
    query GetSubscriptionsForCompany {
        getSubscriptionsForCompany {
            items {
                subscriptionId
                companyId
                status
                subscriptionPlanId
                expiresAt
            }
        }
    }
`

export const QUERY_LIST_PAYMENTS = gql`
    query ListPayments($subscriptionId: String!) {
        listPayments(subscriptionId: $subscriptionId) {
            id
            subscriptionId
            amount
            currency
            payoutDate
            invoiceUrl
        }
    }
`

export const QUERY_LIST_SUBSCRIPTIONS_PLANS = gql`
    ${SUBSCRIPTION_PLAN}
    query ListSubscriptionsPlans {
        listSubscriptionsPlans {
            plans {
                ...SubscriptionPlanFields
            }
        }
    }
`

export const QUERY_GET_PERSONIO_INTEGRATION = gql`
    ${PERSONIO}
    query GetPersonioIntegration {
        getPersonioIntegration {
            ...PersonioFields
        }
    }
`
export const QUERY_GET_SLACK_INTEGRATION = gql`
    ${SLACK}
    query GetSlackIntegration {
        getSlackIntegration {
            ...SlackFields
        }
    }
`

export const QUERY_LIST_NOTIFCATION_INTEGRATIONS = gql`
    query ListNotificationIntegrations {
        listNotificationIntegrations {
            type
            group
            status
        }
    }
`

export const QUERY_GET_SLACK_INSTALL_URL = gql`
    query GetSlackIntegrationInstallUrl {
        getSlackIntegrationInstallUrl
    }
`

export const QUERY_GET_BAMBOOHR_INTEGRATION = gql`
    ${BAMBOOHR}
    query GetBambooHRIntegration {
        getBambooHRIntegration {
            ...BambooHRFields
        }
    }
`

export const QUERY_GET_CALENDAR_SEARCH_EMPLOYEES = gql`
    query GetCalendarEmployeesWithBookings(
        $weekNumber: Int!
        $year: Int!
        $filter: SearchableEmployeeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        employees: searchEmployees(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sort: { field: fullNameLowerCase, direction: asc }
        ) {
            items {
                companyID
                departmentIDs
                departmentsString
                firstname
                lastname
                fullName
                photo
                status
                ...CalendarDataFields
            }
            nextToken
            total
        }
    }

    fragment CalendarDataFields on Employee {
        id
        BookingsByWeek(
            limit: 1000
            yearIsoWeekNumber: { beginsWith: { year: $year, isoWeekNumber: $weekNumber } }
            filter: { isBlocked: { ne: true } }
        ) {
            items {
                id
                startTime
                endTime
                isTeamEvent
                isFullDay
                isBlocked
                BookingRequest {
                    repeatType
                }
                reservable {
                    id
                    type
                    Space {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const QUERY_GET_CALENDAR_SEARCH_FAVORITES_EMPLOYEES = gql`
    query GetCalendarFavoriteEmployees(
        $weekNumber: Int!
        $year: Int!
        $filter: SearchableEmployeeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        employees: searchEmployees(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sort: { field: fullNameLowerCase, direction: asc }
        ) {
            items {
                companyID
                departmentIDs
                departmentsString
                firstname
                lastname
                fullName
                photo
                status
                ...CalendarDataFields
            }
            nextToken
            total
        }
    }

    fragment CalendarDataFields on Employee {
        id
        BookingsByWeek(
            limit: 1000
            yearIsoWeekNumber: { beginsWith: { year: $year, isoWeekNumber: $weekNumber } }
            filter: { isBlocked: { ne: true } }
        ) {
            items {
                id
                startTime
                endTime
                isTeamEvent
                isFullDay
                isBlocked
                BookingRequest {
                    repeatType
                }
                reservable {
                    id
                    type
                    Space {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const QUERY_SEARCH_EMPLOYEES = gql`
    query GetEmployeesForBooking(
        $filter: SearchableEmployeeFilterInput
        $limit: Int
        $nextToken: String
        $date: AWSDate!
    ) {
        searchEmployees(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sort: { field: fullNameLowerCase, direction: asc }
        ) {
            items {
                id
                companyID
                departmentIDs
                firstname
                lastname
                fullName
                photo
                Position {
                    name
                }
                isSeatingInOffice(date: $date)
                status
            }
            nextToken
            total
        }
    }
`

export const QUERY_SEAT_BOOK_WEEK_AGGREAGTION_BY_SPACE = gql`
    query ListSpaceWeekAggregationBySpaceAndYearAndWeekNumber(
        $spaceID: ID!
        $weekNumber: Int
        $year: Int
        $type: AggregationByReservableType
    ) {
        listSpaceWeekAggregationBySpaceAndYearAndWeekNumber(
            spaceID: $spaceID
            yearWeekNumber: { beginsWith: { weekNumber: $weekNumber, year: $year } }
            filter: { type: { eq: $type } }
        ) {
            items {
                weekNumber
                year
                dayAggregations(
                    yearWeekNumberDate: { beginsWith: { weekNumber: $weekNumber, year: $year } }
                    filter: { type: { eq: $type } }
                ) {
                    items {
                        date
                        spaceCapacityLast
                        spaceBookingsSum
                        homeBookingsSum
                    }
                }
            }
        }
    }
`

export const QUERY_SPACE_WEEK_AGGREGATION_BY_COMPANY = gql`
    query ListSpaceWeekAggregationByOfficeAndMoment(
        $officeID: ID!
        $startWeekNumber: String
        $startYear: Int
        $endWeekNumber: String
        $endYear: Int
        $type: AggregationByReservableType
    ) {
        listSpaceWeekAggregationByOfficeAndMoment(
            officeID: $officeID
            yearWeekNumberString: {
                between: [
                    { weekNumberString: $startWeekNumber, year: $startYear }
                    { weekNumberString: $endWeekNumber, year: $endYear }
                ]
            }
            filter: { type: { eq: $type } }
        ) {
            items {
                weekNumber
                year
                spaceID
                spaceCapacityMax
                spaceBookingsSum
                type
                bookedHours
                capacityInHours
                percentageOfUtilization
                Space {
                    name
                }
            }
        }
    }
`

export const QUERY_SPACE_WEEK_AGGREGATION_BY_COMPANY_FOR_WEEK = gql`
    query ListSpaceWeekAggregationByOfficeAndMomentForWeek(
        $officeID: ID!
        $weekNumber: String
        $year: Int
        $type: AggregationByReservableType
    ) {
        listSpaceWeekAggregationByOfficeAndMoment(
            officeID: $officeID
            yearWeekNumberString: { beginsWith: { weekNumberString: $weekNumber, year: $year } }
            filter: { type: { eq: $type } }
        ) {
            items {
                weekNumber
                year
                spaceID
                spaceCapacityMax
                spaceBookingsSum
                type
                bookedHours
                capacityInHours
                percentageOfUtilization
                Space {
                    name
                }
            }
        }
    }
`

export const QUERY_ROOM_WEEK_AGGREGATION_BY_COMPANY = gql`
    query ListRoomWeekAggregationByOfficeAndYearAndWeekNumber(
        $officeID: ID!
        $startWeekNumber: String
        $startYear: Int
        $endWeekNumber: String
        $endYear: Int
    ) {
        listRoomWeekAggregationByOfficeAndMoment(
            officeID: $officeID
            yearWeekNumberString: {
                between: [
                    { weekNumberString: $startWeekNumber, year: $startYear }
                    { weekNumberString: $endWeekNumber, year: $endYear }
                ]
            }
        ) {
            items {
                weekNumber
                year
                reservableID
                Reservable {
                    id
                    name
                }
                roomCapacityMax
                roomBookingsSum
                percentageOfUtilization
                bookedHours
                capacityInHours
            }
        }
    }
`

export const QUERY_ROOM_WEEK_AGGREGATION_BY_COMPANY_FOR_WEEK = gql`
    query ListRoomWeekAggregationByOfficeAndMomentForWeek($officeID: ID!, $weekNumber: String, $year: Int) {
        listRoomWeekAggregationByOfficeAndMoment(
            officeID: $officeID
            yearWeekNumberString: { beginsWith: { weekNumberString: $weekNumber, year: $year } }
        ) {
            items {
                weekNumber
                year
                reservableID
                Reservable {
                    id
                    name
                }
                roomCapacityMax
                roomBookingsSum
                percentageOfUtilization
                bookedHours
                capacityInHours
            }
        }
    }
`

export const QUERY_HOME_OFFICE_COMPANY_WEEK_AGGREGATION = gql`
    query ListCompanyWeekAggregationByCompany($companyID: ID!, $weekNumber: Int, $year: Int) {
        listCompanyWeekAggregationByCompany(
            companyID: $companyID
            yearWeekNumber: { beginsWith: { weekNumber: $weekNumber, year: $year } }
        ) {
            items {
                weekNumber
                year
                dayAggregations(
                    yearWeekNumberDate: { beginsWith: { weekNumber: $weekNumber, year: $year } }
                    filter: { weekNumber: { eq: $weekNumber } }
                ) {
                    items {
                        date
                        officeCapacityLast
                        officeBookingsSum
                        homeBookingsSum
                        type
                    }
                }
            }
        }
    }
`

export const QUERY_ATTENDANCE_WEEK_AGGREGATION_BY_COMPANY = gql`
    query ListAttendanceWeekAggregationByCompanyAndMoment(
        $companyID: ID!
        $weekNumberString: String
        $year: Int
        $currentWeekNumberString: String
        $currentYear: Int
        $type: AggregationByReservableType
    ) {
        listCompanyWeekAggregationByCompanyAndMoment(
            companyID: $companyID
            yearWeekNumberString: {
                between: [
                    { weekNumberString: $weekNumberString, year: $year }
                    { weekNumberString: $currentWeekNumberString, year: $currentYear }
                ]
            }
            filter: { type: { eq: $type } }
        ) {
            items {
                weekNumber
                year
                officeCapacityMax
                officeBookingsSum
                capacityInHours
                bookedHours
                employeesLast
                employeesInOffice
            }
        }
    }
`

export const QUERY_EMPLOYEE_WEEK_ATTENDANCE_AGGREGATION_BY_COMPANY = gql`
    query ListEmployeeWeekAttendanceAggregationByCompanyAndMoment(
        $companyID: ID!
        $weekNumberString: String
        $year: Int
        $currentWeekNumberString: String
        $currentYear: Int
    ) {
        listEmployeeWeekAggregationByCompanyAndMoment(
            companyID: $companyID
            yearWeekNumberString: {
                between: [
                    { weekNumberString: $weekNumberString, year: $year }
                    { weekNumberString: $currentWeekNumberString, year: $currentYear }
                ]
            }
        ) {
            items {
                weekNumber
                year
                officeBookingsSum
                capacityInHours
                bookedHours
                employeesLast
                employeesInOffice
                averageDaysInOffice
            }
        }
    }
`

export const QUERY_UTILIZZATION_WEEK_AGGREAGTION_BY_OFFICE = gql`
    query ListOfficeWeekAggregationByOfficeAndMoment(
        $officeID: ID!
        $weekNumberString: String
        $year: Int
        $currentWeekNumberString: String
        $currentYear: Int
        $type: AggregationByReservableType
        $withDayAggregations: Boolean = false
    ) {
        listOfficeWeekAggregationByOfficeAndMoment(
            officeID: $officeID
            yearWeekNumberString: {
                between: [
                    { weekNumberString: $weekNumberString, year: $year }
                    { weekNumberString: $currentWeekNumberString, year: $currentYear }
                ]
            }
            filter: { type: { eq: $type } }
        ) {
            items {
                weekNumber
                year
                officeCapacityMax
                officeBookingsSum
                capacityInHours
                bookedHours
                dayAggregations(
                    yearWeekNumberStringDate: {
                        between: [
                            { weekNumberString: $weekNumberString, year: $year }
                            { weekNumberString: $currentWeekNumberString, year: $currentYear }
                        ]
                    }
                    filter: { type: { eq: $type } }
                ) @include(if: $withDayAggregations) {
                    items {
                        date
                        officeCapacityLast
                        officeBookingsSum
                        capacityInHours
                        bookedHours
                        homeBookingsSum
                    }
                }
            }
        }
    }
`

export const QUERY_UTILIZZATION_WEEK_AGGREAGTION_BY_OFFICE_FOR_WEEK = gql`
    query ListOfficeWeekAggregationByOfficeAndMomentForWeek(
        $officeID: ID!
        $year: Int
        $weekNumberString: String
        $type: AggregationByReservableType
        $withDayAggregations: Boolean = false
    ) {
        listOfficeWeekAggregationByOfficeAndMoment(
            officeID: $officeID
            yearWeekNumberString: { beginsWith: { weekNumberString: $weekNumberString, year: $year } }
            filter: { type: { eq: $type } }
        ) {
            items {
                weekNumber
                year
                officeCapacityMax
                officeBookingsSum
                capacityInHours
                bookedHours
                dayAggregations(
                    yearWeekNumberStringDate: { beginsWith: { weekNumberString: $weekNumberString, year: $year } }
                    filter: { type: { eq: $type } }
                ) @include(if: $withDayAggregations) {
                    items {
                        date
                        officeCapacityLast
                        officeBookingsSum
                        capacityInHours
                        bookedHours
                        homeBookingsSum
                    }
                }
            }
        }
    }
`

export const QUERY_UTILIZZATION_WEEK_AGGREAGTION_BY_ALL = gql`
    query ListCompanyWeekAggregationByCompanyAndMoment(
        $companyID: ID!
        $weekNumberString: String
        $year: Int
        $currentYear: Int
        $currentWeekNumberString: String
    ) {
        listCompanyWeekAggregationByCompanyAndMoment(
            companyID: $companyID
            yearWeekNumberString: {
                between: [
                    { weekNumberString: $weekNumberString, year: $year }
                    { weekNumberString: $currentWeekNumberString, year: $currentYear }
                ]
            }
            filter: { type: { eq: SEAT } }
        ) {
            items {
                weekNumber
                year
                officeCapacityMax
                officeBookingsSum
                bookingsDistribution {
                    amountOfDays
                    bookingsSum
                }
            }
        }
    }
`

export const QUERY_DEPARTMENT_ATTENDANCE_WEEK_AGGREGATION = gql`
    query ListDepartmentAttendanceWeekAggregation(
        $companyID: ID!
        $weekNumberString: String
        $year: Int
        $currentWeekNumberString: String
        $currentYear: Int
    ) {
        listDepartmentWeekAggregationByCompanyAndMoment(
            companyID: $companyID
            yearWeekNumberString: {
                between: [
                    { weekNumberString: $weekNumberString, year: $year }
                    { weekNumberString: $currentWeekNumberString, year: $currentYear }
                ]
            }
        ) {
            items {
                weekNumber
                year
                officeBookingsSum
                bookedHours
                employeesLast
                departmentID
                employeesInOffice
                averageDaysInOffice
                Department {
                    id
                    name
                }
            }
        }
    }
`

export const QUERY_GET_SPACES = gql`
    query GetSpacesWithReservablesAndCalendarEvents(
        $companyId: ID!
        $type: ReservableType
        $date: String!
        $filter: ModelSpaceFilterInput
    ) {
        listSpacesByCompanyAndName(companyID: $companyId, filter: $filter, sortDirection: ASC) {
            items {
                id
                name
                officeID
                workingHoursFrom
                workingHoursTo
                Office {
                    name
                }
                Reservables(filter: { type: { eq: $type } }, limit: 1000) {
                    items {
                        id
                        name
                        maxAmountOfPeople
                        ReservableToEmployees {
                            items {
                                employee {
                                    id
                                }
                            }
                        }
                        ReservableToDepartments {
                            items {
                                Department {
                                    id
                                }
                            }
                        }
                        Tags {
                            items {
                                Tag {
                                    name
                                }
                            }
                        }
                        availabilityType
                        externalRoomID
                        CalendarEvents(startTime: { beginsWith: $date }) {
                            items {
                                id
                                title
                                description
                                startTime
                                endTime
                                canCurrentUserEdit
                                parentEventExternalId
                                isOnlineMeeting
                                onlineMeetingUrl
                                isPrivate
                                employeesIDs
                                organiserEmployeeID
                                cateringID
                                reservableID
                                Catering {
                                    title
                                    id
                                    email
                                }
                                menuItems {
                                    id
                                    title
                                    description
                                    quantity
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_GET_SPACES_WITH_ROOMS = gql`
    query GetSpacesWithRooms($companyId: ID!, $type: ReservableType, $filter: ModelSpaceFilterInput) {
        listSpacesByCompanyAndName(companyID: $companyId, filter: $filter, sortDirection: ASC) {
            items {
                id
                name
                officeID
                workingHoursFrom
                workingHoursTo
                Office {
                    name
                }
                Reservables(filter: { type: { eq: $type } }, limit: 1000) {
                    items {
                        id
                        name
                        maxAmountOfPeople
                        availabilityType
                        externalRoomID
                        relatedReservableID
                        ReservableToEmployees {
                            items {
                                employee {
                                    id
                                    fullName
                                }
                            }
                        }
                        ReservableToDepartments {
                            items {
                                Department {
                                    id
                                    name
                                }
                            }
                        }
                        Tags {
                            items {
                                id
                                Tag {
                                    id
                                    name
                                }
                            }
                        }
                        ReservableImages {
                            items {
                                name
                                location
                            }
                        }
                        RelatedReservable {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_GET_CALENDAR_EVENTS_BY_OFFICE = gql`
    query GetCalendarEventsByOffice($officeId: ID!, $date: String!, $filter: ModelCalendarEventsFilterInput) {
        listCalendarEventsByOfficeAndStartTime(officeID: $officeId, startTime: { beginsWith: $date }, filter: $filter) {
            items {
                id
                title
                description
                startTime
                endTime
                canCurrentUserEdit
                parentEventExternalId
                isOnlineMeeting
                onlineMeetingUrl
                isPrivate
                employeesIDs
                organiserEmployeeID
                cateringID
                reservableID
                Catering {
                    title
                    id
                    email
                }
                menuItems {
                    id
                    title
                    description
                    quantity
                }
                equipmentItems {
                    id
                    title
                    description
                }
            }
        }
    }
`

export const QUERY_GET_EVENTS_FOR_ROOMS = gql`
    query GetEventsForRooms($reservableId: ID!, $date: String!) {
        listCalendarEventsByReservableAndStartTime(reservableID: $reservableId, startTime: { beginsWith: $date }) {
            items {
                id
                title
                description
                startTime
                endTime
                canCurrentUserEdit
                parentEventExternalId
                isOnlineMeeting
                onlineMeetingUrl
                isPrivate
                Catering {
                    id
                    email
                }
                menuItems {
                    description
                    quantity
                    id
                    title
                }
            }
        }
    }
`

export const QUERY_GET_ATTENDEES_FOR_EVENT = gql`
    query GetAttendeesForEvent($eventId: ID!) {
        getCalendarEvents(id: $eventId) {
            Employees {
                items {
                    Employee {
                        id
                        fullName
                        photo
                        isSeatingInOffice
                        departmentIDs
                        shareAttendance
                    }
                }
            }
            OrganiserEmployee {
                id
                fullName
                photo
                isSeatingInOffice
            }
        }
    }
`

export const QUERY_GET_LIST_OFFICES_BY_COMPANY = gql`
    query GetListOfficesByCompany($companyId: ID!) {
        listOfficesByCompanyAndOrder(companyID: $companyId) {
            items {
                id
                name
                workingFrom
                workingTo
                address
                timeZone
                externalOfficeId
                Catering {
                    companyID
                    id
                    title
                    email
                    enabled
                    officeID
                    MenuItems {
                        items {
                            id
                            title
                            description
                            enabled
                        }
                    }
                }
                SpaceRows {
                    items {
                        id
                        Spaces {
                            items {
                                id
                                floorPlanFilePath
                                name
                                totalSeats
                                officeID
                                totalParkingSlots
                            }
                        }
                    }
                }
            }
        }
    }
`
export const QUERY_CHECK_SEAT_AVAILABLE = gql`
    query GetReservableWithAvailability(
        $employee: [EmployeesWithDepartments]
        $dates: [AWSDate]!
        $bookingRepeatType: RepeatType
        $daysOfWeek: [String]
        $startTime: String
        $endTime: String
        $reservableId: ID!
    ) {
        getReservable(id: $reservableId) {
            id
            availabilityByDatesAndTime(
                bookingRepeatType: $bookingRepeatType
                employees: $employee
                dates: $dates
                daysOfWeek: $daysOfWeek
                startTime: $startTime
                endTime: $endTime
            )
        }
    }
`
export const GET_RESERVABLE_BY_ID = gql`
    ${MAIN_EMPLOYEE}
    query getReservableById(
        $employee: [EmployeesWithDepartments]
        $dates: [AWSDate]
        $bookingRepeatType: RepeatType
        $daysOfWeek: [String]
        $startTime: String
        $endTime: String
        $dateFilter: ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput
        $filter: ModelBookingFilterInput
        $reservableId: ID!
    ) {
        getReservable(id: $reservableId) {
            id
            name
            x
            y
            type
            availabilityType
            maxAmountOfPeople
            zonePoints
            color
            Employee {
                ...MainEmployeeFields
            }
            Space {
                name
                id
                address
                Office {
                    address
                    name
                    id
                }
            }

            Department {
                name
            }

            Tags {
                items {
                    Tag {
                        id
                        name
                    }
                }
            }

            ReservableToDepartments {
                items {
                    Department {
                        id
                        name
                    }
                }
            }

            availabilityByDatesAndTime(
                bookingRepeatType: $bookingRepeatType
                employees: $employee
                dates: $dates
                daysOfWeek: $daysOfWeek
                startTime: $startTime
                endTime: $endTime
            )

            BookingsByDateAndDayOfWeek(limit: 1000, dateDayOfWeek: $dateFilter, filter: $filter) {
                items {
                    id
                    startTime
                    endTime
                    isFullDay
                    isBlocked
                    employeeID
                    Employee {
                        ...MainEmployeeFields
                    }
                }
            }
        }
    }
`
export const QUERY_CATERING_BY_OFFICE_ID = gql`
    query ListCateringsByOffice($filter: ModelCateringFilterInput, $filterMenuItems: ModelMenuItemFilterInput) {
        listCaterings(filter: $filter) {
            items {
                id
                title
                email
                MenuItems(filter: $filterMenuItems) {
                    items {
                        title
                        id
                        description
                        enabled
                    }
                }
            }
        }
    }
`

export const QUERY_LIST_MENU_ITEMS_BY_CATERING_ID = gql`
    query listMenuItemsByCatering($cateringID: ID!, $filter: ModelMenuItemFilterInput) {
        listMenuItemsByCatering(cateringID: $cateringID, filter: $filter) {
            items {
                id
                title
                description
                enabled
            }
        }
    }
`

export const QUERY_SEARCH_RESOURCES = gql`
    ${EQUIPMENT}
    query SearchResources(
        $filter: SearchableEquipmentFilterInput
        $nextToken: String
        $withBookings: Boolean = false
        $filterBookings: ModelBookingEquipmentFilterInput
        $startTime: ModelStringKeyConditionInput
        $limit: Int = 1000
    ) {
        searchEquipment(filter: $filter, limit: $limit, nextToken: $nextToken, sort: { field: name, direction: asc }) {
            items {
                ...EquipmentFields
                Bookings(filter: $filterBookings, startTime: $startTime, limit: 1000) @include(if: $withBookings) {
                    items {
                        id
                        startTime
                        endTime
                        isPrivate
                        employeeID
                        Employee {
                            id
                            fullName
                            photo
                            fullNameLowerCase
                            fullName
                        }
                    }
                }
            }
            nextToken
        }
    }
`

export const QUERY_GET_RESOURCES_BY_OFFICE = gql`
    ${EQUIPMENT}
    query GetResourcesByOffice($officeID: ID!, $filter: ModelEquipmentFilterInput) {
        listEquipmentsByOffice(officeID: $officeID, filter: $filter) {
            items {
                ...EquipmentFields
            }
        }
    }
`

export const QUERY_GET_BOOKING_RESOURCE_BY_OFFICE = gql`
    query GetBookingResourcesByOffice(
        $officeId: ID!
        $isoWeekNumber: Int
        $year: Int
        $filter: ModelBookingEquipmentFilterInput
    ) {
        listBookingEquipmentsByOfficeAndYearAndWeek(
            officeID: $officeId
            yearIsoWeekNumber: { eq: { isoWeekNumber: $isoWeekNumber, year: $year } }
            filter: $filter
            limit: 1000
        ) {
            items {
                id
                startTime
                endTime
                isPrivate
                employeeID
                equipmentID
                Employee {
                    id
                    fullName
                    photo
                    fullNameLowerCase
                    fullName
                }
            }
        }
    }
`
