import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment/moment'

import { type BookingEquipment, Equipment } from 'graphql/autogenerate/schemas'
import { getAWSFormatDate } from 'helpers'

type ResourcesState = {
    officeId: string
    weekNumber: number
    date: string
    year: number
    selectedEvent: BookingEquipment | null
    events: BookingEquipment[]
    resources: Equipment[]
}

export const initialState: ResourcesState = {
    officeId: '',
    selectedEvent: null,
    date: getAWSFormatDate(moment()),
    year: moment().year(),
    weekNumber: moment().isoWeek(),
    events: [],
    resources: [],
}

const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        setOfficeId(state, action: PayloadAction<string>) {
            state.officeId = action.payload
        },
        setDate(state, action: PayloadAction<string>) {
            state.date = action.payload
        },
        setWeekNumberAndYear(state, action: PayloadAction<{ week: number; year: number }>) {
            state.weekNumber = action.payload.week
            state.year = action.payload.year
        },
        setResources(state, action: PayloadAction<Equipment[]>) {
            state.resources = action.payload
        },
        setEvents(state, action: PayloadAction<BookingEquipment[]>) {
            state.events = action.payload
        },
        selectEvent(state, action: PayloadAction<BookingEquipment | null>) {
            state.events = state.events.filter((event) => event.id !== 'temporary')
            state.selectedEvent = action.payload
        },
        resetSelectedEvent(state) {
            state.events = state.events.filter((event) => event.id !== 'temporary')
            state.selectedEvent = null
        },
        addNewEvent(state, action: PayloadAction<BookingEquipment>) {
            // remove the event if it already exists
            state.events = state.events.filter((event) => event.id !== action.payload.id && event.id !== 'temporary')
            state.events.push(action.payload)
            state.selectedEvent = action.payload
        },
        changeEvent(state, action: PayloadAction<Partial<BookingEquipment>>) {
            state.events = state.events.map((event) =>
                event.id === action.payload.id
                    ? {
                          ...event,
                          ...action.payload,
                      }
                    : event,
            )
            if (state.selectedEvent && state.selectedEvent?.id === action.payload.id) {
                state.selectedEvent = {
                    ...state.selectedEvent,
                    ...action.payload,
                    id: state.selectedEvent.id,
                }
            }
        },
    },
})

export const resourcesActions = resourcesSlice.actions
export default resourcesSlice.reducer
