import {
    RepeatType as MobileScheduleRepeatType,
    type Catering,
    type MenuItemModelInput,
} from 'graphql/autogenerate/schemas'

import { TARIFF } from './billing.types'
import { BookingSubType, BookingType } from './common.types'
import { EUserLocale, EUserShareAttendance } from './user.types'
import { EColorPolygone } from '../components/organisms/Reservable/components/Polygone/type'
import { TTag } from '../components/shared/types'

//todo: separate type files/refactor them

export type AbsoluteCoords = {
    x: number
    y: number
    scale?: number
    offset?: number
}

export type TInputOffice = {
    name: string
    shortDescription?: string
    workingHours?: string
    placesTotal?: number
    placesBlocked?: number
    placesFixed?: number
    placesFlex?: number
    phoneNumbers?: (string | null)[]
    properties?: string
    companyID: string
    Company?: TCompany
    Catering?: Catering | null
    OfficeImages?: { items: TOfficeImage[] }
    Spaces?: { items: TSpace[] }
    Address?: TAddress
    Employees?: (TEmployee | null)[]
    officeAddressId?: string
    officeCompanyId?: string
    workingFrom?: string
    workingTo?: string
    address?: string
    timeZone?: string
    externalOfficeId?: string
}

export type TOffice = TInputOffice & {
    id: string
}

export enum TimeUnit {
    MIN = 'MIN',
    HOUR = 'HOUR',
}

export enum TCalendarType {
    GOOGLE = 'GOOGLE',
    MICROSOFT = 'MICROSOFT',
}

export type UsersSettingsType = {
    updateEmployeeDataFromSource?: boolean
    disableProfileEditing?: boolean
    defaultShareAttendance?: EUserShareAttendance
}

export type BookingAndAttendanceFormType = {
    enableMinDaysInOffice: boolean
    minDaysInOffice: number
    minDaysInOfficeTimeUnit: ERulesHorizon
    horizonOfPlanningTimeAmount: number
    horizonOfPlanningTimeUnit: ERulesHorizon
    denyRecurringBookingOverThePlanningHorizon: boolean
    homeIsDefault: boolean
    isPersonalSeatAvailableToday: boolean
    isTeamSeatAvailableToday: boolean
    workingDays: Array<string | undefined>
    blockReservationOnNonWorkingDays: boolean
    turnOnDeletingData: boolean
    deleteDataAfter: number
}

export type NotificationCenterFormType = {
    turnOnCheckIn: boolean
    checkInWaitingTime: number
    checkInWaitingTimeUnit: TimeUnit
    checkInCancellingTime: number
    startTimeOfNotifications: number
    turnOnAttendanceNotifications: boolean
    dayOfAttendanceNotifications: number
    hourOfAttendanceNotifications: number
    turnOffCheckInForPersonalSeats: boolean
    turnOnMonthlyReport: boolean
    emailForMonthlyReport: string
}

export enum ERulesHorizon {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
}

export type TRulesInput = BookingAndAttendanceFormType &
    NotificationCenterFormType &
    UsersSettingsType & {
        maxDaysInOffice: number
        autoAssignWaitingList: boolean
    }

export type TInputCompany = {
    title?: string
    country?: string
    city?: string
    address?: string
    zipcode?: string
    phoneNumber?: string
    numberOfOffices?: number
    numberOfEmployees?: number
    companyLogo: string | null
    timeZone: string
    workingTime: string
    tariff?: TARIFF
    Offices?: { items: TOffice[] }
    Rules?: TRules
    calendarIntegration?: TCalendarType
    trialExpiresAt?: string
}

export type TCompany = TInputCompany & {
    id: string
}

export enum EmployeeStatus {
    INVITED = 'INVITED',
    ADDED = 'ADDED',
    REGISTRED = 'REGISTRED',
    UNREGISTRED = 'UNREGISTRED',
}

export enum ENotificationChannel {
    SLACK = 'SLACK',
    EMAIL = 'EMAIL',
    MS_TEAMS = 'MS_TEAMS',
    DISABLED = 'DISABLED',
}

export enum ES3OperationType {
    GET_S3_OBJECT = 'getObject',
    PUT_S3_OBJECT = 'putObject',
}

export enum EReservableType {
    AWAY = 'AWAY',
    HOME = 'HOME',
    SEAT = 'SEAT',
    ROOM = 'ROOM',
    ZONE = 'ZONE',
    PARKING = 'PARKING',
    VACATION = 'VACATION',
    BUSINESS_TRIP = 'BUSINESS_TRIP',
    SICK_LEAVE = 'SICK_LEAVE',
}

export enum EAvailabilityType {
    FREE_FOR_ALL = 'FREE_FOR_ALL',
    BOOKED_FOR_PERSON = 'BOOKED_FOR_PERSON',
    BOOKED_FOR_TEAM = 'BOOKED_FOR_TEAM',
    UNAVALIABLE = 'UNAVALIABLE',
}

export enum ERoleEmployee {
    EMPLOYEE = 'EMPLOYEE',
    MANAGER = 'MANAGER',
    TEAM_LEAD = 'TEAM_LEAD',
    OWNER = 'OWNER',
}

export enum SyncSource {
    BAMBOOHR = 'BAMBOOHR',
    PERSONIO = 'PERSONIO',
    SCIM = 'SCIM',
    SCIM_AD = 'SCIM_AD',
    SCIM_GOOGLE = 'SCIM_GOOGLE',
    UNDEFINED = 'UNDEFINED',
    SSO_OIDC = 'SSO_OIDC',
    SSO_SAML = 'SSO_SAML',
}

export type TInputEmployee = {
    email: string
    firstname?: string
    lastname?: string
    photo?: string | null
    Office?: TOffice
    officeID?: string | null
    Company?: TCompany
    companyID: string
    Position?: TPosition
    positionID?: string | null
    EmployeeToDepartment?: { items: Array<{ Department: TDepartment }> }
    departmentIDs?: string[]
    employeeDepartmentIDs?: string[]
    departmentsString?: string
    favouriteOfficeID?: string | null
    status?: EmployeeStatus
    locale?: EUserLocale
    shareAttendance?: EUserShareAttendance
    Plans?: {
        items: (TPlan | null)[]
    }
    Bookings?: {
        items: Array<TBooking>
    }
    defaultPlan?: string[]
    employeeDepartmentId?: string | null
    employeeCompanyId?: string
    employeeOfficeId?: string | null
    employeePositionId?: string | null
    notificationChannels?: ENotificationChannel[]
    createdAt?: string
    role?: ERoleEmployee
    source?: SyncSource
    favouriteColleagueIDs?: string[]
    favouriteReservableID?: string | null
    birthday?: string | null
    buddyID?: string | null
    turnOnExperimentalFeatures?: boolean | null
}

export type TEmployee = TInputEmployee & {
    id: string
    fullName?: string
}

// todo: increase this type
export type InputUpdateEmployee = {
    id: string
    companyID: string
    employeeCompanyId: string
    role: ERoleEmployee
}

export type TInputBooking = {
    startTime: string
    endTime: string
    status: string
    reservableID: string
    employeeID: string
    companyID: string
    bookingReservableId: string
    bookingEmployeeId: string
    bookingCompanyId: string
    Employee?: TEmployee
    reservable?: TReservable
    isTeamEvent?: boolean
}
export type TBooking = TInputBooking & {
    id: string
}

export type TInputPlan = {
    from?: string
    to?: string
    workplace?: string
    status?: string
    Employee?: TEmployee
    employeeID: string
    date: string
    Company?: TCompany
    companyID?: string
    planCompanyId?: string
    planEmployeeId?: string
}

export type TPlan = TInputPlan & {
    id: string
}

export type TInputAddress = {
    country?: string
    city?: string
    address?: string
    zipcode?: string
    lat?: number
    long?: string
    companyID: string
    addressCompanyId?: string
}

export type TAddress = TInputAddress & {
    id: string
}

export type TInputPosition = {
    name: string
    Company?: TCompany
    companyID: string
    Employees?: (TEmployee | null)[]
    positionCompanyId?: string
    departmentCompanyId?: string
}

export type TPosition = TInputPosition & {
    id: string
}

export type TInputRole = {
    name: string
}

export type TRole = TInputRole & {
    id: ERoleEmployee
}

export type TDepartment = {
    id: string
    name: string
    companyID: string
}

export type TStatus = {
    id: EmployeeStatus
    name: string
}

export type TInputOfficeImage = {
    name?: string
    location?: string
    Office?: TOffice
    officeID: string
    companyID: string
    officeImageOfficeId?: string
    officeImageCompanyId?: string
}

export type TOfficeImage = TInputOfficeImage & {
    id: string
}

export type TQuizInput = {
    title: string
    companyID?: string
    employeeID: string
    QuizKeys?: {
        items: (TQuizKey | null)[]
    }
}

export type TQuiz = TQuizInput & {
    id: string
}

export type TQuizKeyInput = {
    Quiz?: TQuiz
    quizID: string
    companyID?: string
    question: string
    answer: string
    number: number
    quizKeyQuizId?: string
}

export type TQuizKey = TQuizKeyInput & {
    id: string
}

export type TSpaceInput = {
    companyID: string
    spaceCompanyId?: string
    officeID: string
    spaceOfficeId?: string
    floorPlanFilePath?: string
    Office?: TOffice
    totalSeats?: number
    totalRooms?: number
    totalParkingSlots?: number
    name: string
    workingHoursFrom?: string
    workingHoursTo?: string
    address?: string
    isAvailableToEmployees?: boolean
    Reservables?: { items: TReservable[] }
}

export type TSpace = TSpaceInput & {
    id: string
}

export type MainEmployeeData = {
    id: string
    photo: string | null
    firstname: string
    lastname: string
    fullName: string
    departmentIDs?: string[]
    departmentsString?: string
    favouriteColleagueIDs?: string[]
    EmployeeToDepartment?: { items: Array<{ Department: TDepartment }> }
    status: EmployeeStatus
    favouriteOfficeID?: string
    officeID?: string
    favouriteReservableID?: string
    Company?: TCompany | null
}

export type ReservableEmployeesType = {
    employeeID: string
    employee: Omit<TEmployee | MainEmployeeData, 'companyID'>
    id: string
}

export type ReservableDepartmentsType = {
    departmentID: string
    Department: Omit<TDepartment, 'companyID'>
    id: string
}

export type TReservableInput = {
    id?: string
    companyID: string
    spaceID: string
    type: EReservableType
    availabilityType: EAvailabilityType
    employeeID?: string | null
    relatedReservableID?: string | null
    RelatedReservable?: TReservable
    Employee?: TEmployee | MainEmployeeData | null
    Department?: {
        name: string
    }
    Space?: TSpace
    departmentID?: string | null
    ReservableToEmployees?: {
        items: Array<ReservableEmployeesType>
    }
    ReservableToDepartments?: {
        items: Array<ReservableDepartmentsType>
    }
    CalendarEvents?: { items: Array<CalendarEventType> }
    name?: string | null
    maxAmountOfPeople?: number | null
    x: number
    y: number
    externalRoomID?: string | null
    zonePoints?: number[]
    color?: EColorPolygone
    admin?: TEmployee | MainEmployeeData | null
}

export type TReservable = TReservableInput & {
    id: string
}

export enum EPersonioStatus {
    SYNCED = 'SYNCED',
    ERROR = 'ERROR',
    SYNCING = 'SYNCING',
    DISABLED = 'DISABLED',
}

export enum EBambooHRStatus {
    NO_SYNC_STARTED = 'NO_SYNC_STARTED',
    FIRST_TIME_SYNC_FAILED = 'FIRST_TIME_SYNC_FAILED',
    FAILED = 'FAILED',
    SYNCING = 'SYNCING',
    SUCCESS = 'SUCCESS',
}

export type TPersonioInput = {
    clientID: string
    clientSecret: string
}

export type TPersonio = TPersonioInput & {
    companyID: string
    status: EPersonioStatus
    hasInvitationEnabled: boolean
    hasSyncBookingsToPersonioEnabled: boolean
    error?: string
    nextSyncAt?: string
    lastSyncedAt?: string
}

export type TBambooHR = {
    companyID: string
    hasInvitationEnabled: boolean
    syncStatus: EBambooHRStatus
    lastSyncAt?: string
    companyDomain: string
}

export type TSlackInput = {
    sendDayScheduleAt?: string
    enableSendingDaySchedule: boolean
    enableCheckInFeature: boolean
}

export type TSlack = TSlackInput & {
    companyID: string
}

export type TRules = TRulesInput & {
    id: string
}

/**
 * space rooms
 **/

export type CalendarEventEmployeeType = {
    id: string
    name: string
    profilePic: string | null
    isOrganiser: boolean
}

export type CalendarEventType = {
    id: string
    reservableID?: string
    title: string
    description: string | null
    startTime: string
    endTime: string
    canCurrentUserEdit: boolean
    isPrivate?: boolean
    isNewEvent?: boolean
    dayOfMonth?: number
    daysOfWeek?: Array<string>
    firstDayOfWeek?: string
    interval?: number
    month?: number
    numberOfOccurrences?: number
    patternType?: RecurrencePatternType
    rangeEndDate?: string
    rangeStartDate?: string
    rangeType?: RecurrenceRangeType
    recurrenceTimeZone?: string
    weekIndex?: string
    parentEventExternalId?: string
    cateringID?: string
    menuItems?: Array<MenuItemModelInput>
}

export enum RecurrencePatternType {
    ABSOLUTE_MONTHLY = 'ABSOLUTE_MONTHLY',
    ABSOLUTE_YEARLY = 'ABSOLUTE_YEARLY',
    DAILY = 'DAILY',
    RELATIVE_MONTHLY = 'RELATIVE_MONTHLY',
    RELATIVE_YEARLY = 'RELATIVE_YEARLY',
    WEEKLY = 'WEEKLY',
}

export enum RecurrenceRangeType {
    END_DATE = 'END_DATE',
    NO_END_DATE = 'NO_END_DATE',
    NUMBERED = 'NUMBERED',
}

export type TCreateCalendarEventInput = {
    companyID: string
    employeesIDs: Array<string>
    organiserEmployeeID?: string
    title: string
    description: string | null
    reservableID: string
    startTime?: string
    endTime?: string
    spaceID?: string | null
    dayOfMonth?: number
    daysOfWeek?: Array<string>
    firstDayOfWeek?: string
    interval?: number
    month?: number
    numberOfOccurrences?: number
    patternType?: RecurrencePatternType
    rangeEndDate?: string
    rangeStartDate?: string
    rangeType?: RecurrenceRangeType
    recurrenceTimeZone?: string
    weekIndex?: string
    cateringID?: string
    menuItems?: Array<MenuItemModelInput>
}

export type TUpdateCalendarEventInput = TCreateCalendarEventInput & {
    id: string
}

export type TCreateTeamEventInput = {
    employeeIDs: Array<string>
    date: string
    title: string
    repeatType: MobileScheduleRepeatType | null
    daysOfWeek: Array<string> | null
    description: string | null
    startTime?: string
    endTime?: string
    spaceID: string | null
    companyID: string
    reservableID: string | null
    dates: Array<string> | null
}

export type BookingRoom = {
    title: string
    startTime: string
    endTime: string
    id: string
    canCurrentUserEdit: boolean
}

export enum PIN_CIRCLE {
    EMPTY = 'EMPTY',
    FIRST_HALF = 'FIRST_HALF',
    SECOND_HALF = 'SECOND_HALF',
    FULL = 'FULL',
}

type CalendarRowDataReservable = {
    id: string
    type: EReservableType
    Space: {
        id: string
        name: string
    } | null
} | null

export type BookingResponse = {
    id: string
    startTime: string
    endTime: string
    isTeamEvent: boolean | null
    employeeID: string
    companyID: string
    owner: string | null
    isFullDay: null | boolean
    bookingType: BookingType
    bookingSubType: BookingSubType
    BookingRequest: {
        repeatType: 'WEEKLY' | null
    } | null
    TeamEventRequest: {
        repeatType: 'WEEKLY' | null
    } | null
    reservable: CalendarRowDataReservable
}

export type CalendarRowBooking = Pick<
    BookingResponse,
    | 'id'
    | 'startTime'
    | 'endTime'
    | 'isTeamEvent'
    | 'reservable'
    | 'isFullDay'
    | 'BookingRequest'
    | 'TeamEventRequest'
    | 'bookingType'
    | 'bookingSubType'
> & { isBlocked?: boolean }

export type CalendarRowData = {
    id: string
    BookingsByWeek: {
        items: Array<CalendarRowBooking>
    }
}

export type CalendarEmployee = {
    id: string
    companyID: string
    departmentIDs: string[]
    firstname: string
    lastname: string
    fullName: string
    photo: string | null
    email: string
    Position: {
        name: string
    } | null
    status: EmployeeStatus
    isSeatingInOffice?: boolean
    employeeFullName: string
}

export type Department = null | { name: string; id: string }

export type Position = null | { name: string; id: string }

export type Office = null | { name: string; id: string }

export type DemoAccount = null | {
    createdAt: string
    email: string
}

export type PickerReservableBooking = {
    id: string
    startTime: string
    endTime: string
    employeeID: string
    isFullDay: boolean | null
    isBlocked: boolean | null
    Employee: SeatPickerReservable['Employee']
}

export type SeatPickerReservable = {
    id: string
    name: string
    x: number
    y: number
    type: EReservableType
    availabilityType: EAvailabilityType
    availabilityPreview: Array<PIN_CIRCLE>
    availabilityPreviewByDates: Array<PIN_CIRCLE>
    Employee: null | MainEmployeeData
    Department: Department
    maxAmountOfPeople: number
    zonePoints?: number[]
    color?: string
    availabilityByDatesAndTime?: boolean
    spaceID: string
    BookingReservablesByCreatedAt: {
        items: Array<{
            booking: PickerReservableBooking
        }>
    }
    ReservableToEmployees?: {
        items: Array<ReservableEmployeesType>
    }
    ReservableToDepartments?: {
        items: Array<{ Department: Department }>
    }
    BookingsByDateAndDayOfWeek: {
        items: Array<PickerReservableBooking>
    }
    Tags: {
        items: Array<{ Tag: TTag }>
    }
    Space?: TSpace | null
}

export type SpaceRoomType = {
    id: string
    name: string | null
    x: number
    y: number
    type: 'ROOM'
    maxAmountOfPeople: number | null
    preview: Array<PIN_CIRCLE>
    bookings: Array<BookingRoom>
    availabilityType: EAvailabilityType
}

export type SpaceWithRooms = Pick<
    TSpace,
    'id' | 'name' | 'workingHoursFrom' | 'workingHoursTo' | 'officeID' | 'Reservables'
> & {
    items: Array<{
        id: string
        name: string
        maxAmountOfPeople: number
        Employee?: TEmployee | MainEmployeeData | null
        ReservableToEmployees?: {
            items: Array<{
                Employee: {
                    id: string
                }
            }>
        }
        ReservableToDepartments: {
            items: Array<{
                Department: {
                    id: string
                }
            }>
        }
        Tags?: {
            items?: { Tag?: TTag }[]
        }
        availabilityType: EAvailabilityType
        CalendarEvents: { items: Array<CalendarEventType> }
        externalRoomID?: string
    }>
}

export type SelectedRoomType = {
    roomId: string
    name: SpaceRoomType['name']
    from: string
    to: string
    spaceName: string
    maxAmountOfPeople: SpaceRoomType['maxAmountOfPeople']
}

export enum ResizeFit {
    contain = 'contain',
    cover = 'cover',
    fill = 'fill',
    inside = 'inside',
    outside = 'outside',
}

export type ImageResizeInput = {
    fit: ResizeFit
    targetHeight?: number
    targetWidth?: number
}
