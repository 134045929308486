import { gql } from '@apollo/client'

import {
    BAMBOOHR,
    BOOKING,
    DEPARTMENT,
    EMPLOYEE,
    EQUIPMENT,
    OFFICE,
    PERSONIO,
    POSITION,
    RESERVABLE,
    RULES,
    SPACE,
    SUBSCRIBED_BILLING,
    SUBSCRIPTION_FOR_COMPANY,
} from './dataQuery'

// Department
export const SUBSCRIPTION_CREATE_DEPARTMENT = gql`
    ${EMPLOYEE}
    ${DEPARTMENT}
    subscription OnCreateDepartment($companyID: String!) {
        onCreateDepartment(companyID: $companyID) {
            ...DepartmentFields
            TeamLead {
                ...EmployeeFields
            }
            DepartmentRules {
                blockReservationOnNonOfficeDays
                enableMinDaysInOffice
                minDaysInOffice
                officeDays
                minDaysInOfficeTimeUnit
            }
            EmployeeToDepartment {
                items {
                    id
                    Employee {
                        ...EmployeeFields
                    }
                }
            }
        }
    }
`

export const SUBSCRIPTION_UPDATE_DEPARTMENT = gql`
    ${DEPARTMENT}
    ${EMPLOYEE}
    subscription OnUpdateDepartment($companyID: String!) {
        onUpdateDepartment(companyID: $companyID) {
            ...DepartmentFields
            TeamLead {
                ...EmployeeFields
            }
            DepartmentRules {
                blockReservationOnNonOfficeDays
                enableMinDaysInOffice
                minDaysInOffice
                officeDays
                minDaysInOfficeTimeUnit
            }
            EmployeeToDepartment {
                items {
                    id
                    Employee {
                        ...EmployeeFields
                    }
                }
            }
        }
    }
`

export const SUBSCRIPTION_DELETE_DEPARTMENT = gql`
    ${DEPARTMENT}
    subscription OnDeleteDepartment($companyID: String!) {
        onDeleteDepartment(companyID: $companyID) {
            ...DepartmentFields
        }
    }
`

// Position
export const SUBSCRIPTION_CREATE_POSITION = gql`
    ${POSITION}
    subscription OnCreatePosition($companyID: String!) {
        onCreatePosition(companyID: $companyID) {
            ...PositionFields
        }
    }
`

export const SUBSCRIPTION_UPDATE_POSITION = gql`
    ${POSITION}
    subscription OnUpdatePosition($companyID: String!) {
        onUpdatePosition(companyID: $companyID) {
            ...PositionFields
        }
    }
`

export const SUBSCRIPTION_DELETE_POSITION = gql`
    ${POSITION}
    subscription OnDeletePosition($companyID: String!) {
        onDeletePosition(companyID: $companyID) {
            ...PositionFields
        }
    }
`

// Reservable
export const SUBSCRIPTION_CREATE_RESERVABLE = gql`
    ${RESERVABLE}
    ${EMPLOYEE}
    ${DEPARTMENT}
    ${POSITION}
    subscription OnCreateReservable($companyID: String!) {
        onCreateReservable(companyID: $companyID) {
            ...ReservableFields
            Employee {
                ...EmployeeFields
                Position {
                    ...PositionFields
                }
            }
            Department {
                ...DepartmentFields
            }
        }
    }
`

export const SUBSCRIPTION_UPDATE_RESERVABLE = gql`
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    subscription OnUpdateReservable($companyID: String!) {
        onUpdateReservable(companyID: $companyID) {
            ...ReservableFields
            Employee {
                ...EmployeeFields
                Position {
                    ...PositionFields
                }
            }
            ReservableToDepartments {
                items {
                    id
                }
            }
            ReservableToEmployees {
                items {
                    employee {
                        id
                    }
                }
            }
            Department {
                ...DepartmentFields
            }
            admin {
                ...EmployeeFields
            }
        }
    }
`

export const SUBSCRIPTION_DELETE_RESERVABLE = gql`
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    subscription OnDeleteReservable($companyID: String!) {
        onDeleteReservable(companyID: $companyID) {
            ...ReservableFields
            Employee {
                ...EmployeeFields
                Position {
                    ...PositionFields
                }
            }
            Department {
                ...DepartmentFields
            }
        }
    }
`

// Space
export const SUBSCRIPTION_CREATE_SPACE = gql`
    ${SPACE}
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    subscription OnCreateSpace($companyID: String!) {
        onCreateSpace(companyID: $companyID) {
            ...SpaceFields
            Reservables {
                items {
                    ...ReservableFields
                    Employee {
                        ...EmployeeFields
                        Position {
                            ...PositionFields
                        }
                    }
                    Department {
                        ...DepartmentFields
                    }
                }
            }
        }
    }
`

export const SUBSCRIPTION_UPDATE_SPACE = gql`
    ${SPACE}
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    subscription OnUpdateSpace($companyID: String!) {
        onUpdateSpace(companyID: $companyID) {
            ...SpaceFields
            Reservables {
                items {
                    ...ReservableFields
                    Employee {
                        ...EmployeeFields
                        Position {
                            ...PositionFields
                        }
                    }
                    bookingReservables {
                        items {
                            id
                        }
                    }
                    Department {
                        ...DepartmentFields
                    }
                }
            }
        }
    }
`

export const SUBSCRIPTION_DELETE_SPACE = gql`
    ${SPACE}
    ${RESERVABLE}
    ${EMPLOYEE}
    ${POSITION}
    ${DEPARTMENT}
    subscription OnDeleteSpace($companyID: String!) {
        onDeleteSpace(companyID: $companyID) {
            ...SpaceFields
            Reservables {
                items {
                    ...ReservableFields
                    Employee {
                        ...EmployeeFields
                        Position {
                            ...PositionFields
                        }
                    }
                    Department {
                        ...DepartmentFields
                    }
                }
            }
        }
    }
`

export const SUBSCRIPTION_CREATE_EMPLOYEE = gql`
    ${EMPLOYEE}
    ${POSITION}
    ${OFFICE}
    ${BOOKING}
    ${RESERVABLE}
    subscription OnCreateEmployeeWithBookings($companyID: String!) {
        onCreateEmployee(companyID: $companyID) {
            ...EmployeeFields
            Position {
                ...PositionFields
            }
            Office {
                ...OfficeFields
            }
            Bookings {
                items {
                    ...BookingFields
                    reservable {
                        ...ReservableFields
                    }
                }
            }
        }
    }
`
export const SUBSCRIPTION_UPDATE_EMPLOYEE = gql`
    ${EMPLOYEE}
    ${POSITION}
    ${OFFICE}
    subscription OnUpdateEmployeeWithBookings($companyID: String!) {
        onUpdateEmployee(companyID: $companyID) {
            ...EmployeeFields
            Position {
                ...PositionFields
            }
            Office {
                ...OfficeFields
            }
        }
    }
`

export const SUBSCRIPTION_DELETE_EMPLOYEE = gql`
    ${EMPLOYEE}
    ${POSITION}
    ${OFFICE}
    ${RESERVABLE}
    ${BOOKING}
    subscription OnDeleteEmployeeWithBookings($companyID: String!) {
        onDeleteEmployee(companyID: $companyID) {
            ...EmployeeFields
            Position {
                ...PositionFields
            }
            Office {
                ...OfficeFields
            }
            Bookings {
                items {
                    ...BookingFields
                    reservable {
                        ...ReservableFields
                    }
                }
            }
        }
    }
`

export const SUBSCRIPTION_BAMBOOHR = gql`
    ${BAMBOOHR}
    subscription OnUpdatedBambooHRIntegration($companyId: String!) {
        onUpdatedBambooHRIntegration(companyId: $companyId) {
            ...BambooHRFields
        }
    }
`

export const SUBSCRIPTION_PERSONIO = gql`
    ${PERSONIO}
    subscription OnLogPersonioIntegrationExecutionResult($companyID: String!) {
        onLogPersonioIntegrationExecutionResult(companyID: $companyID) {
            ...PersonioFields
        }
    }
`

// Billing
export const SUBSCRIPTION_BILLING_UPDATE = gql`
    subscription OnUpdateBillingSubscription($companyId: String!) {
        onUpdateBillingSubscription(companyId: $companyId) {
            quantity
            updateUrl
            cancelUrl
            subscribedAt
            subscriptionId
            discount {
                type
                value
            }
            companyId
            status
            subscriptionPlanId
            expiresAt
        }
    }
`

export const SUBSCRIPTION_COMPANY_TARIFF_UPDATE = gql`
    subscription OnCompanyTariffUpdate($companyId: String!) {
        onCompanyTariffUpdate(companyId: $companyId) {
            companyId
            tariff
        }
    }
`

export const SUBSCRIPTION_COMPANY_RULES_UPDATE = gql`
    ${RULES}
    subscription OnUpdateRules($companyId: String!) {
        onUpdateRules(id: $companyId) {
            ...RulesFields
        }
    }
`

export const SUBSCRIPTION_UPDATE_USER = gql`
    subscription OnUpdateUser($companyId: String!, $id: String!) {
        onUpdateEmployeeById(companyID: $companyId, id: $id) {
            id
            firstname
            lastname
            cognitoUserId
            email
            photo
            role
            locale
            favouriteOfficeID
            departmentIDs
            Position {
                id
                name
            }
            Office {
                id
                name
            }
            notificationChannels
            shareAttendance
            demoAccountID
            favouriteColleagueIDs
            favouriteReservableID
            turnOnExperimentalFeatures
        }
    }
`

export const SUBSCRIPTION_CREATE_ROOM_EVENT = gql`
    subscription OnCreateRoomEvent($companyID: String!, $reservableID: String!) {
        onCreateCalendarEventByReservable(companyID: $companyID, reservableID: $reservableID) {
            id
            startTime
            endTime
            companyID
            isOnlineMeeting
            onlineMeetingUrl
        }
    }
`

export const SUBSCRIPTION_UPDATE_ROOM_EVENT = gql`
    subscription OnUpdateRoomEvent($companyID: String!, $reservableID: String!) {
        onUpdateCalendarEventByReservable(companyID: $companyID, reservableID: $reservableID) {
            id
            startTime
            endTime
            companyID
            isOnlineMeeting
            onlineMeetingUrl
        }
    }
`

export const SUBSCRIPTION_DELETE_ROOM_EVENT = gql`
    subscription OnDeleteRoomEvent($companyID: String!, $reservableID: String!) {
        onDeleteCalendarEventByReservable(companyID: $companyID, reservableID: $reservableID) {
            id
            startTime
            endTime
            companyID
            isOnlineMeeting
            onlineMeetingUrl
        }
    }
`

export const SUBSCRIPTION_CREATE_RESOURCE = gql`
    ${EQUIPMENT}
    subscription OnCreateResource($companyID: String!) {
        onCreateEquipment(companyID: $companyID) {
            ...EquipmentFields
        }
    }
`

export const SUBSCRIPTION_UPDATE_RESOURCE = gql`
    ${EQUIPMENT}
    subscription OnUpdateResource($companyID: String!) {
        onUpdateEquipment(companyID: $companyID) {
            ...EquipmentFields
        }
    }
`

export const SUBSCRIPTION_DELETE_RESOURCE = gql`
    ${EQUIPMENT}
    subscription OnDeleteResource($companyID: String!) {
        onDeleteEquipment(companyID: $companyID) {
            ...EquipmentFields
        }
    }
`

export const SUBSCRIPTION_CREATE_BOOKING_BY_RESERVABLE = gql`
    ${BOOKING}
    subscription OnCreateBookingByReservable($companyID: String!, $reservableID: String!) {
        onCreateBookingByReservable(companyID: $companyID, reservableID: $reservableID) {
            ...BookingFields
        }
    }
`

export const SUBSCRIPTION_UPDATE_BOOKING_BY_RESERVABLE = gql`
    ${BOOKING}
    subscription OnUpdateBookingByReservable($companyID: String!, $reservableID: String!) {
        onUpdateBookingByReservable(companyID: $companyID, reservableID: $reservableID) {
            ...BookingFields
        }
    }
`

export const SUBSCRIPTION_DELETE_BOOKING_BY_RESERVABLE = gql`
    ${BOOKING}
    subscription OnDeleteBookingByReservable($companyID: String!, $reservableID: String!) {
        onDeleteBookingByReservable(companyID: $companyID, reservableID: $reservableID) {
            ...BookingFields
        }
    }
`
